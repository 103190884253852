.App {
  text-align: center;
  display: flex;
  align-items: center;
  background-color: #ffe8cf;
}

/* HomePage */

#root {
  width: 100%;
}

#homeHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px outset rgb(216, 216, 216);
/*  padding-top: 10px;*/
  background-color: #E39200;
  font-family: "Arial";
  font-weight: bold;
/*  height: 120px;*/
  box-shadow: 0px 0px 30px #925E00;
}

#aboutHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px outset rgb(216, 216, 216);
  box-shadow: 4px 3px rgb(232, 232, 232);
  background-color: #E39200;
  padding-top: 10px;
  font-weight: bold;
}

#homeBody {

  min-height: 100vh;
  background-color: #ffe8cf;
}

#M:hover {
  transform: scale(1.1);
}

#M, #git, #linkedIn {
  width: 20px;
  margin: 15px;
/*  margin-left: 25px;*/
  transition: 0.3s;
  border: 2px solid gray;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 3px 3px lightgray;
  background-color: white;
}

#git:hover, #linkedIn:hover {
  transform: scale(1.1);
}

/*#homeMartaTracker {
  border-right: 2px solid #895800;
  margin-right: 20px;
  padding: 20px;
  padding-right: 30px;
  padding-left: 15px;
}*/

#homeIcon {
  display: flex;
  align-items: center;
  font-size: 20px;
}

#homeLinks {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;
}

#homeLinks a {
  cursor: default;
}

#homeLinks img {
  cursor: pointer;
}

#homeAboutMe p {
  font-size: 20px;
  border-radius: 7px;
  margin-left: 20px;
/*  padding: 13px;*/
  transition: 0.3s;
}

#homeAboutMe p:hover {
  cursor: pointer;
  transform: scale(1.05);
}

/* Homepage content */

#homeText {
/*  margin-top: 170px;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.LinesPage {
  background-color: rgb(253, 255, 242);
  height: 100vh;
}

#GOAT {
	background: linear-gradient(45deg, #c2341b 0%, #e37f05 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-family: 'Arial';
  font-weight: bold;
  transition: 0.3s;
}

#GOAT:hover {
  transform: scale(1.04);
  cursor: pointer;
}

#toprow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: monospace;
}

#homeTitle {
  font-size: 5rem;
  text-align: center;
  margin-top: 8vh;
  display: flex;
  flex-direction: column;
}

@media(max-width: 700px) {
  #homeTitle {
    font-size: 2.5rem;
  }
  #homeContent {
    font-size: .75rem !important;
  }
}

#homeContent {
  text-align: center;
/*  font-size: 25px;*/
  font-size: 1rem;
  font-weight: 300;
  font-family:monospace;
  color: black;
  margin: 10px;
  
}

#buttons {
  display: flex;
  flex-direction: row;
/*  margin-top: 20px;
  margin-bottom: 20px;*/
}

@media (max-width: 630px) {
  #buttons {
    display: block !important
  }
  #homeFooter {
    display: none !important
  }

#homeBody {
  overflow: hidden;
}

  #homeReact, #homeMarta {
    margin-top: 30px;
  }
}

#buttons p {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 70px;
  width: 150px;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 1.5rem;
  font-weight: 800;
  font-family:monospace;
  text-align: center;
  padding: 0;
  transition: 0.3s;
  box-shadow: 3px 3px grey;
}

#goldButton {
  border: 2px double #B7A100;
  background-color: #FFEF76;
}
#redButton {
  border: 2px double red;
  background-color: #ffd0c2;
}
#greenButton {
  border: 2px double green;
  background-color: #c3ffbd;
}

#goldButton:hover {
  background-color: rgba(255, 217, 0, 0.286);
}
#redButton:hover {
  background-color: rgba(255, 0, 0, 0.294);
}
#blueButton:hover {
  background-color: rgba(135, 207, 235, 0.537);
}
#greenButton:hover {
  background-color: rgba(48, 140, 48, 0.43);
}

#buttons p:hover {
  cursor: pointer;
  transform: scale(1.1)
}


/* footer */
#homeFooter {
  margin-top: auto;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#homeFooterComponents {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 600px;
  height: 120px;
  font-weight: 300px;
  margin-top: 20px;
  font-size: 20px;
}

#mart {
  width: 190px;
  margin: 10px;
  padding-left: 10px;
}

#reaction {
  min-width: 120px;
}

#homeReact, #homeMarta {
 display:flex;
 flex-direction: column;
 align-items: center;
 padding-bottom: 0px;
 height: 80px;
 width: 200px;
 align-self: center;
}


#bottomreact {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#topreact {
/*  margin-left: 20px; */
}

#topmarta {
/*  margin-left: 30px;*/
}

#homeReact img {
  width: 80px
}

@media (prefers-reduced-motion: no-preference) {
/*  #logo {
    animation: App-logo-spin infinite 20s linear;
  }*/
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}








#heading-content {
  display: flex;
  align-self: center;
  flex-direction: column;
  border: 2px solid lightgrey;
  width: 800px;
  height: 500px;
}

#heading {
  display: flex;
  justify-content: center;
  border-bottom: 2px solid lightgrey;
  width: 780px;
  padding: 10px;
  margin-top: 0;
  margin: 0;
}
#leftNav-trains {
  display: flex;
  flex-direction: row;
  justify-content: space-between;;
}
#leftNav {
  display: flex;
  height: 435px;
  width: 190px;
  background-color: rgb(35, 35, 35);
}

#allTrains {
  display: flex;
  flex-direction: column;
/*  max-height: 385px;*/
  width: 600px;
  overflow-y: auto;
}

.LinesPage {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}



/*
Train.js train components
*/

#locations-time {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid rgb(0, 0, 0);
}

#letter-location {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

#letter-location h1 {
  font-family: "Roboto slab";
  font-weight: 900;
  font-size: 40px;
/*  margin-right: 5px;*/
}

#letter-location h1 {
  margin: 20px;
}

#location-line {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: center;
}

#line-onTime {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}


#GOLD {
  background-color: rgb(157, 135, 6);
  margin-right: 20px;
  padding-top: 1px;
  padding-bottom: 2px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 4px;
  color: white;
}

#BLUE {
  background-color: rgb(60, 84, 220);
  margin-right: 20px;
  padding-top: 1px;
  padding-bottom: 2px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 4px;
  color: white;
}

#GREEN {
  background-color: rgb(37, 142, 37);
  margin-right: 20px;
  padding-top: 1px;
  padding-bottom: 2px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 4px;
  color: white;
}

#RED {
  background-color: rgb(212, 41, 41);
  margin-right: 20px;
  padding-top: 1px;
  padding-bottom: 2px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 4px;
  color: white;
}

#backHome {
  position: absolute;
  top: 10px; /* Position from the top edge */
  left: 20px; /* Position from the left edge */
  width: 150px;
  height: 40px;
  font-size: 20px;
  font-weight: bold;
}

#time {
  color: green;
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

#num-time {
  margin: 0;
}

#min-time {
  margin: 0;
}

/* Navbar CSS */

#navigation {
  color: lightgrey;
  padding: 0px;
  margin: 0px;
}

#selectYour {
  font-size: 12px;
}

#stationList {
  display: flex;
  list-style-type: none;
  flex-direction: column;
  overflow-y: auto;
  max-height: 398px;
  max-width: 200px;
  margin: 0px;
  align-items: flex-start;
  padding: 0px;
  overflow-x: hidden;
}

li {
  white-space: nowrap;
  border-bottom: 2px solid lightgrey;
  padding: 10px;
  padding-left: 20px;
  margin: 0px;
  width: 140px;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
}

li:hover {
  background-color: rgb(154, 154, 154);
}

#filters {
  display: flex;
  justify-content: flex-end;
  margin-right: 65px
}

#filters p:hover {
  cursor: pointer;
}

#phtext {
  font-weight: normal;
  font-family: monospace;
  font-size: 1.5rem !important;
}

#east {
  margin-right: 7px;
  margin-left: 6px;
}

#west {
  margin-right: 3px;
  margin-left: 3px;
}

#filters p {
  border: 3px solid lightgray;
  padding: 2px;
  padding-left: 9px;
  padding-right: 9px;
  font-weight: 300;
  margin: 10px;
  border-radius: 8px;
}

#loading {
  font-family: "Arial";
  font-size: 20px;
  display: flex;
  justify-content: center;
  margin-top: 150px;
  margin-left: 0px;
  color: lightgrey;
}

.toggled {
  background-color: rgb(77, 77, 77);
  border-left: 2px dashed rgb(178, 184, 0);
  width: 136px;
}

#aboutBody {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  height: 100vh;
  overflow: hidden;
  background-color: #ffe8cf;
}

#aboutBody h1 {
  text-align: center;
  font-size: 60px;
  margin: 30px;
  font-weight: 500;
  font-family: "Consolas";
}

#aboutContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 0;
  overflow: hidden;
}

#aboutTrain {
  width: 750px;
}

#aboutText {
  text-indent: 30px;
  height: 800px;
  width: 750px;
  margin: 50px;
  font-size: 29px;
  font-family: "consolas"
}

#bold-about {
  color: #9c0207;
}

#madeBy {
  position: absolute;
  bottom: 0px;
  right: 20px;
  color: grey;
  font-size: 12px;
}


/* Player CSS */

#player-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70vh;
  max-width: 500px;
  height: 100px;
  border: 5px solid black;
  border-radius: 10px;
  transition: 0.3s;
  margin: 20px;
  background-color: white;
}

#standing-str {
  font-size: 10px;
}

#team-str {
  white-space: nowrap;

}

@media (max-width: 680px) {
  #player-card {
    width: 50vh !important;
    max-width: 400px !important;
  }
  #player-rank {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
  }
#standing-str {
  display: none;
}
/*  #player-rank-numbers {
    display: none !important;
  }*/
}

@media (max-width: 500px) {
  #player-card {
    width: 50vh !important;
  }
  #PL-search-bar {
    width: 300px !important;
    margin-right: 15px;
  }
/*  #player-rank-numbers {
    display: none !important;
  }*/
  .hl-pl {
    display: none !important;
  }
}

@media (max-width: 415px) {
  #player-card {
    width: 40vh !important;
  }
  #player-name {
    font-size: 15px !important;
  }
  #team-str {
    font-size: 10px !important;
  }
}

#player-card:hover {
  transform: scale(1.05)
}

#player-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
}

#player-plaque {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin-right: 20px;
}

#player-image {
  border-radius: 100%;
/*  margin: 20px; */
  margin-bottom: 8px;
}

#player-percentile {
  font-size: 12.5px;
  font-weight: 600;
/*  margin-bottom: 16px;*/
}

#player-text {
  display: flex;
  flex-direction: column;
}

#player-name {
  font-size: 20px;
  font-weight: 500;
  white-space: nowrap;

/*  padding: 10px;*/
}

#player-team {
  display: flex;
  
}

#player-team-image {
  display:flex;
  justify-content: center;
  border-radius: 5px;
  align-self: center;
/*  margin: 10px;*/
}

#player-team-description {
  font-size: 15px;
  padding: 10px;
}

#player-rank-numbers {
  display: flex;
  align-self: flex-start;
  justify-content: flex-end;
  align-items: flex-start;
}


#player-rank-change-green {
  display: flex;
  flex-direction: row;
  font-size: 28px;
  color: green;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border-radius: 15px;
}

#player-rank-change-red {
  display: flex;
  flex-direction: row;
  font-size: 28px;
  color: red;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 10px;

  border-radius: 15px;
}

@media (max-width: 680px) {
#player-rank-change-red {
  margin: 5px;
  padding: 2.5px;
}
#player-rank-change-green {
  margin: 5px;
  padding: 2.5px;

}
}

#player-rank {
  width: 50px;
  height: 50px;
  background-color: black;
  color: white;
  font-weight: 900;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 5px;
}
/**
  Individual Team component styling
**/

#team-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 900px;
  height: 250px;
  border: 5px solid black;
  border-radius: 40px;
  transition: 0.3s;
  margin: 35px;
  background-color: white;
}

#team-card:hover {
  transform: scale(1.05)
}

#team-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

#team-image {
  border-radius: 100%;
  margin: 20px;
/*  margin-bottom: 8px;*/
}

#team-percentile {
  font-size: 25px;
  font-weight: 600;
}

#team-text {
  display: flex;
  flex-direction: column;
}

#team-name {
  font-size: 40px;
  font-weight: 500;
  padding: 10px;
}

#team-standing {
  font-size: 25px;
  padding: 10px;
}

#team-rank-numbers {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-content: flex-end;
  align-items: flex-start;
}

@media (max-width: 680px) {
#team-rank-numbers {
  align-self: flex-end;
}
#player-name {
  white-space: normal !important;
  font-size: .8rem;
}

#player-info {
  margin-left: 0px !important;
}

}

#team-rank-change-green {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  color: green;
  justify-content: center;
  align-items: center;
  margin: 27px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 15px;
}

#team-rank-change-red {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  color: red;
  justify-content: center;
  align-items: center;
  margin: 27px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 15px;
}

#team-rank {
  width: 130px;
  height: 130px;
  background-color: black;
  color: white;
  font-weight: 900;
  font-size: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 30px;
}

.hh {
  position: fixed;
  width: 100%;
  z-index: 10;
}

/**
  Player List component styling
**/

#wholePL {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
/*  max-height: 90vh;*/
  z-index: 1;
}

#PL-container {
  padding-top: 80px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px;
  background-color: #FFF5BF; 
/*  max-height: 66vh;*/
  min-height: 66vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 60vw;
  padding-top: 20px;
  margin: 50px;
  border: 3px double black;
  margin-top: 10px;
  margin-bottom: 23px;
/*  margin-left: 100px;*/
}

#PL-search-bar {
/*  margin: 20px;*/
  padding: 15px;
  font-size: 20px;
  max-width: 800px;
  height: 40px;
  border-radius: 12px;
  height: 10px;
  width: 500px;
} 

#PL-cant-find {
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 100px;
  font-size: 30px;
  color: grey;
}

#WholeApp {
}

#last-updated {
  font-size: 1rem;
  white-space: nowrap;
}

#whole-players-page h1 {
  align-self: center;
  display: flex;
  justify-content: center;
  font-size: 40px;
}

#whole-players-page {
  background-color: #ffe8cf;;
}

#Legend {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 35vh;
  left: 180px;
  width: 320px;
  height: 350px;
  margin: 0px;
  background-color: white;
  border: 5pt solid black;
  padding: 10px;
  z-index: 0;
}

#Legend h1 {
  font-size: 30px;
}

#Legend p {
  margin: 5px;
  font-weight: 900;
  font-size: 25px;
}

#mvp-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;  
}

#mvp-page h1 {
  margin: 20px;
  
}

#mvp-title {
  margin-top: 0px;
  font-size: 50px;
  text-align: center;
  display: flex;
  flex-direction: row;
}

#mvp-image img {
  align-self: center;
  height: 400px;
  width: auto;
  border: 2px black solid;
}
#mvp-page h2 {
  font-size: 40px;
}

#mvp-title #GOAT {
  background: linear-gradient(45deg, #c2341b 0%, #e37f05 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Arial';
  margin-left: 24px;
  padding-top: 18px;
  transition: 0.3s;
}

#mvp-page p {
  font-size: 30px;
  margin: 8px;
}

#mvp-page #aboutHeading {
  background-color: rgb(254, 255, 246);
}

#mvp-whole {
  background-color: #ffe8cf;
}